import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import LanguageSwitcher from '../LanguageSwitcher';
import InfoItem from '../../react-web-ui/components/RestaurantInfo/InfoItem';
import useWorkingHours from '../../react-web-ui/hooks/useWorkingHours';
import BookRestaurantForm from '../BookRestaurantForm';
import MenuSection from '../MenuSection';
import RequestService from '../../networking/RequestService';
import { ReactComponent as Clock } from '../../react-web-ui/assets/images/icons/Clock.svg';
import { ReactComponent as MapIcon } from '../../react-web-ui/assets/images/icons/Map.svg';
import { ReactComponent as Cuisine } from '../../react-web-ui/assets/images/icons/Cuisine.svg';
import { ReactComponent as Chef } from '../../react-web-ui/assets/images/icons/Chef.svg';
import Footer from '../Footer';
// import Loader from '../../assets/images/loader.gif';

export default function Restaurant() {
  const { t } = useTranslation();
  const params = useParams();
  const countryContext = useContext(CountryContext);
  const [restaurantInfo, setRestaurantInfo] = useState([]);
  const [workingHours] = useWorkingHours(restaurantInfo, [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')], t('closed'));

  useEffect(() => {
    (new RequestService('client/places/details'))
      .setParams({
        slug: params.slug,
      })
      .send()
      .then((response) => {
        setRestaurantInfo(response.data);
      })
      .catch(() => null);
  }, [params.slug, t]);

  if (!restaurantInfo.id) {
    return (
      <div className={styles['loader-wrapper']}><img src="../../assets/images/loader.gif" alt="" /></div>
    );
  }

  return (
    <div className="widget-landing">
      <div className={`page-header ${styles['restaurant-header']}`}>
        <div className="inner-wrapper">
          <h1 className={styles['restaurant-name']}>
            <strong>{restaurantInfo.name}</strong>
            {restaurantInfo.subtitle ? '—' : ''}
            {restaurantInfo.subtitle}
          </h1>
          <div className="header-right">
            <LanguageSwitcher locales={['en', countryContext.country.code.toLowerCase()]} />
          </div>
        </div>
      </div>
      <div className={styles['restaurant-info-wrapper']}>
        <div className="inner-wrapper">
          <div className={styles['restaurant-info']}>
            <InfoItem
              icon={<MapIcon className="icon" />}
              title={(
                <>
                  {`${t('pageRestaurantProfile:location')} `}
                  <a
                    href={`https://www.google.bg/maps/place/${restaurantInfo.lat},${restaurantInfo.lng}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-accent"
                  >
                    {`(${t('pageRestaurantProfile:see_map')})`}
                  </a>
                </>
              )}
              text={`${restaurantInfo.city.name}, ${restaurantInfo.neighbourhood.name}, ${restaurantInfo.address}`}
            />
            {(workingHours && !restaurantInfo.isPermanentlyClosed) ? (
              <InfoItem
                icon={<Clock className="icon" />}
                title={t('pageRestaurantProfile:accepts_bookings')}
                text={(
                  <>
                    {workingHours.map((hours) => (
                      <span key={hours} className={styles['working-hours']}>{hours}</span>
                    ))}
                  </>
                )}
              />
            ) : null}
            {restaurantInfo.chefName && (
              <InfoItem
                icon={<Chef className="icon" />}
                title={t('pageRestaurantProfile:chef_name')}
                text={restaurantInfo.chefName}
              />
            )}
            <InfoItem
              icon={<Cuisine className="icon" />}
              title={t('pageRestaurantProfile:contacts')}
              text={(
                <>
                  <a href={`tel:${restaurantInfo.contactPhoneNumber}`}>{restaurantInfo.contactPhoneNumber}</a>
                  <a href={`maito:${restaurantInfo.contactEmail}`}>{restaurantInfo.contactEmail}</a>
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="inner-wrapper">
        <div className="lg-two-cols">
          <div className="col">
            <BookRestaurantForm
              restaurantId={restaurantInfo.id}
              restaurantInfo={restaurantInfo}
            />
          </div>
          <div className="col">
            <MenuSection
              restaurantId={restaurantInfo.id}
              name={restaurantInfo.name}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
