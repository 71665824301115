import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import Tooltip from '../../Tooltip';

export const allergenMap = [
  'GLUTEN_KEY',
  'CRUSTACEANS_KEY',
  'EGGS_KEY',
  'FISH_KEY',
  'PEANUT_KEY',
  'SOYBEANS_KEY',
  'MILK_KEY',
  'NUTS_KEY',
  'CELERY_KEY',
  'MUSTARD_KEY',
  'SESAME_KEY',
  'SULPHUR_DIOXIDE_AND_SULPHIDES_KEY',
  'LUPIN_KEY',
  'MOLLUSCS_KEY',
];

/**
 * Menu item
 * @param icon
 * @param name
 * @param price
 * @param description
 * @param allergens
 * @returns {*}
 * @constructor
 */
export default function MenuItem({
  name,
  price,
  description,
  allergens,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles['menu-item']}>
      <div className={styles['text']}>
        <p>
          {name}
        </p>
        <small>
          {description}
          {allergens && (
            <span className={styles['allergens']}>
              {allergens.map((allergen) => (
                <Tooltip key={allergen} title={t(allergen)} position="bottom">
                  <span>{allergenMap.indexOf(allergen) + 1}</span>
                </Tooltip>
              ))}
            </span>
          )}
        </small>
      </div>
      <div className={styles['price']}>{price}</div>
    </div>
  );
}

MenuItem.propTypes = {
  /** Name of menu item. */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /** Price of menu item. */
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
  /** Description of menu item. */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  allergens: PropTypes.arrayOf(PropTypes.string),
};

MenuItem.defaultProps = {
  description: '',
  allergens: [],
  price: '',
};
