import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import RequestService from '../../networking/RequestService';
import StillTasting from '../../assets/images/still-tasting.svg';
import Menu from './Menu';

export default function MenuSection({
  restaurantId,
  name,
}) {
  const { t } = useTranslation();
  const [restaurantMenu, setRestaurantMenu] = useState([]);

  useEffect(() => {
    const requestMenuItems = new RequestService('client/menu/get');

    requestMenuItems.setParams({
      place_id: restaurantId,
    })
      .send()
      .then((response) => {
        setRestaurantMenu(response.data);
      })
      .catch(() => null);
  }, [restaurantId]);

  return (
    <section data-testid="menu-section" className="section">
      <h2 className="section-title">
        <strong>{name}</strong>
        {` ${t('pageRestaurantProfile:section_menu_title')} `}
      </h2>
      {restaurantMenu.length > 0 ? (
        <Menu restaurantMenu={restaurantMenu} />
      ) : (
        <div className={styles['no-menu']}>
          <img src={StillTasting} alt="" />
          {t('pageRestaurantProfile:still_tasting')}
        </div>
      )}
    </section>
  );
}

MenuSection.propTypes = {
  restaurantId: PropTypes.string,
  name: PropTypes.string,
};

MenuSection.defaultProps = {
  restaurantId: '',
  name: '',
};
